<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    width="492px"
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template>
      <!-- BODY -->
      <div class="ml-2 mt-2">
        <!-- <label
          >ID <span class="font-weight-bolder">{{ link.id }}</span></label
        > -->
        <h3 class="number-color text-uppercase font-weight-bolder">
          {{ link.name }}
        </h3>

        <!-- <label>Creado el 20/05/2021 16:26:17</label> -->
      </div>
      <!-- <div class="ml-2">
        <b-badge class="pill-style" pill variant="light-success">
          Activa
        </b-badge>
      </div> -->
      <div v-if="verificaEstado(link)">
        <b-badge
          class="pill-style font-weight-normal"
          pill
          variant="light-success"
        >
          Activo
        </b-badge>
      </div>
      <div v-else>
        <b-badge
          class="pill-style font-weight-normal"
          pill
          variant="light-danger"
          >Inactivo</b-badge
        >
      </div>

      <!-- Primer cuadro -->
      <div class="ml-2 color-container py-2">
        <!--  -->

        <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Tipo</label>
          </b-col>
          <b-col>
            <label class="contenido-tab">{{
              link.type == 2 ? "Suscripción" : "Pago único"
            }}</label>
          </b-col>
        </b-row>

        <!--  -->

        <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="RotateCwIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Periodicidad</label>
          </b-col>
          <b-col>
            <label class="contenido-tab">{{
              link.periodicidad == null
                ? "-"
                : link.periodicidad == 1
                ? "Diaria"
                : link.periodicidad == 2
                ? "Semanal"
                : link.periodicidad == 3
                ? "Quincenal"
                : link.periodicidad == 4
                ? "Mensual"
                : link.periodicidad == 5
                ? "Trimestral"
                : link.periodicidad == 6
                ? "Semestral"
                : "Anual"
            }}</label>
          </b-col>
        </b-row>

        <!--  -->

        <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="RefreshCwIcon"
              size="16"
              class="text-body align-middle icon-rotate"
            />
            <label class="font-weight-bolder ml-1">Permanente </label>
          </b-col>
          <b-col>
            <label class="contenido-tab">{{
              link.permanent == null ? "-" : link.permanent == 1 ? "Sí" : "No"
            }}</label>
          </b-col>
        </b-row>

        <!--  -->

        <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="CreditCardIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Medio de Pago</label>
          </b-col>
          <b-col>
            <!-- [
              {"text":"Tarjeta de Crédito","value":1,"pasarela":[1,2]},
              {"text":"Tarjeta de Débito","value":2,"pasarela":[1]},
              {"text":"Efectivo","value":3,"pasarela":[1]},
              {"text":"Transferencia","value":4,"pasarela":[1]}
            ] -->
            <!-- {{JSON.parse(link.metodoPago)}} -->
            <div v-for="value in JSON.parse(link.metodoPago)" :key="value">
              <label class="contenido-tab">
                {{
                  value === 1
                    ? "Tarjeta Credito"
                    : value == 2
                    ? "Tarjeta Debito"
                    : value == 3
                    ? "Efectivo"
                    : value == 4
                    ? "Transferencia"
                    : value == 5
                    ? "Entel"
                    : value == 6
                    ? "Movistar"
                    : "-"
                }}
              </label>
            </div>
            <!-- <label v-for="metodo in link.metodoPago" class="contenido-tab">
              {{ link.metodoPago }}
            </label> -->
          </b-col>
        </b-row>

        <!--  -->

        <!-- <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="Minimize2Icon"
              size="16"
              class="text-body align-middle icon-rotate"
            />
            <label class="font-weight-bolder ml-1">Transacciones</label>
          </b-col>
          <b-col>
            <label class="contenido-tab">1</label>
          </b-col>
        </b-row> -->

        <!--  -->

        <b-row class="mb-1 align-items-center">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="StopCircleIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Moneda</label>
          </b-col>
          <b-col>
            <label class="contenido-tab">
              {{
                link.currencyString
              }}
            </label>
          </b-col>
        </b-row>

        <!--  -->

        <b-row class="mb-1">
          <b-col class="ancho-item-titulo">
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="text-body align-middle"
            />
            <label class="font-weight-bolder ml-1">Monto</label>
          </b-col>
          <b-col class="margin-monto">
            <b-row>
              <b-col class="ancho-monto-detail">
                <label>Subtotal</label>
              </b-col>
              <b-col class="margin-monto">
                <label
                  >{{
                    link.currencyString

                  }}
                  {{ link.amount }}</label
                >
              </b-col>
            </b-row>
            <!-- <b-row>
                  <b-col class="ancho-monto-detail">
                    <label >Impuestos</label>
                  </b-col>
                  <b-col class="margin-monto">
                    <label >${{link.amount_iva}}</label>
                  </b-col>
                </b-row> -->
            <b-row>
              <b-col class="ancho-monto-detail">
                <label class="font-weight-bolder">Total</label>
              </b-col>
              <b-col class="margin-monto">
                <label class="font-weight-bolder"
                  >{{
                    link.currencyString
                  }}
                  {{ link.amount }}</label
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <!-- Segundo cuadro -->
      <div class="ml-2 color-container py-2">
        <!-- Icono y Título -->
        <div>
          <b-row class="mb-1">
            <b-col>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Información</label>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Producto o Servicio</label
              >
            </b-col>
            <b-col>
              <label class="contenido-tab">{{ link.name }}</label>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Texto promocional</label
              >
            </b-col>
            <b-col>
              <label class="contenido-tab">{{ link.textPromo }}</label>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Descripción</label
              >
            </b-col>
            <b-col>
              <label class="contenido-tab descripcionOverflow">{{ link.description }}</label>
            </b-col>
          </b-row>

          <b-row class="mb-1 align-items-center">
            <b-col>
              <feather-icon
                icon="ImageIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Imagen</label>
            </b-col>
          </b-row>

          <b-row class="mb-1 align-items-center">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Nombre de archivo</label
              >
            </b-col>
            <b-col>
              <label class="contenido-tab">{{ link.url_imgage }}</label>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1 align-items-center">
            <b-col>
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="text-body align-middle"
              />
              <label class="font-weight-bolder ml-1">Programación</label>
            </b-col>
          </b-row> -->

          <b-row class="mb-1">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Validez</label
              >
            </b-col>
            <b-col class="margin-monto">
              <b-row>
                <b-col class="ancho-validez-detail">
                  <label>Fecha de lanzamiento</label>
                </b-col>
                <b-col class="margin-validez-fecha">
                  <label class="font-weight-bolder">{{
                    link.date_from == "Invalid date"
                      ? "-"
                      : formatDate(new Date(link.date_from), "dd/MM/yyyy")
                  }}</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="ancho-validez-detail">
                  <label>Fecha de expiración</label>
                </b-col>
                <b-col class="margin-validez-fecha">
                  <label class="font-weight-bolder">{{
                    link.date_to == null
                      ? "-"
                      : formatDate(new Date(link.date_to), "dd/MM/yyyy")
                  }}</label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col class="ancho-item-titulo">
              <label class="font-weight-bolder margin-inner-item"
                >Términos y Condiciones
              </label>
            </b-col>
            <b-col>
              <label class="contenido-tab">{{
                link.Terms_and_Conditions
              }}</label>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BBadge,
  BIcon,
  BIconstack,
  BootstrapVue,
  BootstrapVueIcons,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { integer } from "vee-validate/dist/rules";

export default {
  components: {
    BRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    BIcon,
    BIconstack,
    BootstrapVueIcons,
    BootstrapVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    link: {},
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
   
    irTransacciones() {
      this.$router.push("/dashboard/transaccion");
    },
    formatDate(date, format) {
      const map = {
        MM:
          date.getMonth() + 1 < 11
            ? '0' + (date.getMonth() + 1)
            : '' + (date.getMonth() + 1),
        dd: date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate(),
        //yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear(),
        hh: date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours(),
        mm:
          date.getMinutes() < 10
            ? '0' + date.getMinutes()
            : '' + date.getMinutes(),
        ss:
          date.getSeconds() < 10
            ? '0' + date.getSeconds()
            : '' + date.getSeconds(),
      };
      return format.replace(/MM|dd|yyyy|hh|mm|ss/gi, (matched) => map[matched]);
    },
    verificaEstado(item) {
      var hoy = new Date();
      var dateFrom = new Date(item.date_from);
      if (dateFrom > hoy) {
        return false;
      }
      if (item.date_to != null) {
        var dateTo = new Date(item.date_to + " 23:59");
        if (dateTo < hoy) {
          return false;
        }
      }
      return true;
    },
  },
  mounted() {
    console.log('linkkk:::', this.link)
    switch (this.link.metodoPago) {
      case 1:
        "Tarjeta de Credito";
        break;
      case 2:
        "Tarjeta de Debito";
        break;
      case 3:
        "Efectivo";
        break;
      case 4:
        "Transferencia";
    }
    // this.metodos = this.link.metodoPago;
    // this.ver();
  },
  data() {
    return {
      required,
      // metodos: [],
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .color-container {
    background: #eeeeee;
    width: 90%;
    margin: 16px 16px;
    border-radius: 8px;
    padding-left: 32px;
  }

  .icon-rotate {
    transform: rotate(45deg);
  }

  .number-color {
    color: black;
  }

  .pill-style {
    margin-left: 20px;
    margin-bottom: 2px;
  }

  .contenido-tab {
    display: block;
    margin-left: 40px;
    width: 204px;
  }

  .descripcionOverflow{
    white-space: nowrap; 
     
    overflow: hidden;
    text-overflow: ellipsis; 
    display: inline-block;
  }

  #contenido-tab-monto {
    margin-left: 72px;
  }

  .margin-inner-item {
    margin-left: 30px;
  }

  .ancho-item-titulo {
    width: 140px;
  }

  .margin-monto {
    margin-left: 53px;
  }

  .margin-validez-fecha {
    margin-left: 20px;
  }

  .ancho-monto-detail {
    width: 40px;
  }

  .ancho-validez-detail {
    width: 120px;
  }
}
</style>
