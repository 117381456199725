<template>
  <section>
    <div>
      <LinkPagoDetalle
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
        :link="link"
      />
    </div>

    <!-- <div id="loading-bg" v-if="loading">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div> -->

    <!-- Table Container Card -->
    <b-col cols="14">
      <b-card class="mb-0">
        <!-- Header -->
        <b-card-header class="px-0">
          <div class="container p-0">
            <div class="row align-items-center">
              <div class="col-12">
                 <b-alert
                v-height-fade.appear
                :show="dismissCountDown"
                dismissible
                class="mb-0"
                variant="info"
                id="id-popup"
                @dismissed="dismissCountDown = 0"
              >
                <div class="alert-body">
                  <span class="clipboard-text">{{ linkCopiado }} </span>
                </div>
              </b-alert>
              </div>
              <div class="col-lg col-12">
                <b-card-title> Links de pago </b-card-title>
              </div>
              <div class="col-lg col-12">
                <div style="display: flex">
                  <div
                    v-if="$store.state.appConfig.rol == 3"
                    style="display: flex; margin-left: auto"
                  >
                    <router-link to="/dashboard/linksPago/LinkPagoCrear">
                      <b-button variant="primary" class="shadow-none"
                        >Crear Links de Pago</b-button
                      >
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-header>

        <!-- Tabla -->
        <b-card v-if="linksSinFiltrar" no-body class="card-company-table">
          <div>
            <!-- :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc" -->

            <b-table
              id="my-table"
              style="white-space: nowrap"
              class="position-relative mb-0 text-center table-div"
              :items="linksDepago($store.state.appConfig.projecto.id)"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :per-page="porPagina"
              :current-page="paginaActual"
              empty-text="No se han encontrado links de pago"
              hover
              selectable
              select-mode="single"
              no-select-on-click
              @row-clicked="rowClicked"
            >
              <!-- @row-selected="onRowSelected" -->
              <!-- fecha -->
              <template #cell(date_from)="data">
                <div class="align-items-center">
                  <div>
                    <div class="data-items-size">
                      {{
                        data.item.date_from == 'Invalid date'
                          ? '-'
                          : data.item.date_from
                      }}
                    </div>
                  </div>
                </div>
              </template>

              <!-- producto -->
              <template #cell(name)="data">
                <div class="align-items-center data-items-size">
                  <span>{{ data.item.name }}</span>
                </div>
              </template>

              <!-- id -->
              <template #cell(id)="data">
                <div class="d-flex flex-column data-items-size">
                  <span>{{ data.item.id }}</span>
                </div>
              </template>

              <!-- tipo pago -->
              <template #cell(type)="data">
                <div class="d-flex flex-column data-items-size">
                  <span>{{
                    data.item.type == '2' ? 'Suscripción' : 'Pago único'
                  }}</span>
                </div>
              </template>

              <!-- transacciones -->
              <!-- <template #cell(transacciones)="data">
                <div class="d-flex flex-column data-items-size">
                  <span>{{ data.item.transacciones }}</span>
                </div>
              </template> -->

              <!-- estado -->
              <template #cell(estado)="data">
                <!-- <div v-if="data.item.date_remove === null"> -->
                <div v-if="data.item.estado">
                  <b-badge
                    class="pill-style font-weight-normal"
                    :key="estado"
                    pill
                    variant="light-success"
                  >
                    Activo
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge
                    class="pill-style font-weight-normal"
                    :key="estado"
                    pill
                    variant="light-danger"
                    >Inactivo</b-badge
                  >
                </div>
              </template>

              <!-- detalle -->
              <template #cell(accion)="data">
                <div class="align-items-center">
                  <b-button
                    @click="handleDetail(data.item)"
                    class="action-button mr-2"
                    variant="none"
                    v-b-tooltip.hover
                    title="Mas información"
                    id="detalle-link-pago"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="text-body align-middle"
                    />
                  </b-button>
                  <!-- Hacer el popup de link copiado -->
                  <button
                    v-b-tooltip.hover
                    title="Copiar enlace"
                    class="action-button mr-1"
                    v-if="verificaEstado(data.item)"
                    @click="
                      () => {
                        showAlert(), copiar(data.item.id, data.item.pass);
                      }
                    "
                    variant="none"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="16"
                      class="text-body align-middle"
                    />
                  </button>

                  <b-dropdown
                    v-if="$store.state.appConfig.rol == 3"
                    variant="none"
                    no-caret
                    toggle-class="p-0"
                    menu-class="clase-menu"
                    right
                    v-b-tooltip.hover
                    title="Mas opciones"
                    id="menu-options"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="handleEdit(data.item.id)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="handleRemove(data.item.id)"
                      v-b-modal.modal-eliminar-link
                    >
                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <b-row class="paginador" v-if="rowsLength > 0">
              <b-col cols="12" md="6" lg="6" class="mx-0">
                <p>Cantidad: {{ rowsLength }}</p>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="mx-0 overflow-auto" >
                <b-pagination
                  v-model="paginaActual"
                  :total-rows="rowsLength"
                  :per-page="porPagina"
                  aria-controls="my-table"
                  first-text="Primera"
                  prev-text="Anterior"
                  next-text="Siguiente"
                  last-text="Ultima"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <!-- Cierre Tabla-->

        <!-- Footer Table Paginación -->
        <!--

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Página {{ dataMeta.from }} de {{ dataMeta.to }} a
                {{ dataMeta.of }}</span
              >
            </b-col>
            
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"

              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        -->
      </b-card>
    </b-col>

    <b-modal
      id="modal-center"
      title="Filtrar por"
      v-model="modalShow"
      centered
      size="sm"
      @ok="validateDates"
      hide-footer
      ref="modal-center"
    >
      <b-form>
        <b-form-group
          label="Fecha de Creación"
          label-for="select-fecha"
          label-class="font-weight-clipboard-texter label-size"
          class="line"
        >
          <b-row>
            <b-col>
              <b-form-datepicker
                id="desde-datepicker"
                v-model="valueDesde"
                class="btn-outline-info btn-filtro"
                selected-variant="info"
                button-variant="btn-info"
                label-no-date-selected="Desde"
                nav-button-variant="info"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              />
            </b-col>
            <b-col>
              <b-form-datepicker
                id="hasta-datepicker"
                v-model="valueHasta"
                class="btn-outline-info btn-filtro"
                selected-variant="info"
                button-variant="btn-info"
                label-no-date-selected="Hasta"
                nav-button-variant="info"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback v-if="errorDate">
            La fecha inicial debe ser menor a la fecha final.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row class="mt-3 margen">
          <b-col>
            <b-button
              variant="primary"
              class="modal-button-size shadow-none"
              @click="validateDates"
            >
              Aplicar filtro
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- Eliminar Link de pago -->
    <b-modal
      id="modal-eliminar-link"
      body-bg-variant="light-warning"
      v-model="modalEliminarLinkShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-eliminar-link"
    >
      <label id="label-confirmacion">
        ¿Seguro que quieres eliminar este link de pago?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideEliminarModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="agregar-button-modal"
            @click="eliminarLinkDePago(idRemove)"
          >
            Eliminar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BAlert,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import UsersListFilters from '../UsersListFilters.vue';
import useUsersList from '../linksPago/useUsersList';
import userStoreModule from '../../userStoreModule';
import LinkPagoDetalle from '../linksPago/LinkPagoDetalle.vue';
import Button from '@/views/components/button/Button.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { backendUrl, frontEndUrl } from '@/config';
import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    UsersListFilters,
    LinkPagoDetalle,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BForm,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    Button,
    ToastificationContent,
    BInputGroupAppend,
    BAlert,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ];

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ];

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];

    const tableColumns = [
      { key: 'id', sortable: true },
      { key: 'date_from', label: 'Fecha', sortable: true },
      { key: 'name', label: 'Producto o Servicio', sortable: true },
      { key: 'type', label: 'Tipo de Pago', sortable: true },
      { key: 'estado', label: 'Estado', sortable: true },
      { key: 'accion' },
    ];

    const {
      fetchUsers,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
  mounted() {
    this.getLinkDePago();
  },
  data() {
    return {
      modalEliminarLinkShow: false,
      valueHasta: null,
      valueDesde: null,
      modalShow: false,
      link: {},
      linksSinFiltrar: [],
      errorDate: false,
      dismissSecs: 30,
      dismissCountDown: 0,
      idRemove: null,
      linkCopiado: '',
      porPagina: 5,
      paginaActual: 1,
      rowsLength: null,
    };
  },
  methods: {
    handleEdit(value) {
      this.$store.commit('appConfig/linkCarga', value);
      this.$router.push({ path: '/dashboard/linksPago/LinkPagoEditar' });
    },
    verificaEstado(item) {
      var hoy = new Date();
      var dateFrom = new Date(item.date_from);
      if (dateFrom > hoy) {
        return false;
      }

      if (item.date_to != null) {
        var dateTo = new Date(item.date_to + ' 23:59');
        if (dateTo < hoy) {
          return false;
        }
      }
      if (item.permanent == 0 /*  && id_transaction != null */) {
        if (item.id_transaction != null) {
          return false;
        }
      }
      return true;
    },
     formatDate(date, format) {
      const map = {
        MM:
          date.getMonth() + 1 < 11
            ? '0' + (date.getMonth() + 1)
            : '' + (date.getMonth() + 1),
        dd: date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate(),
        //yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear(),
        hh: date.getHours() < 10 ? '0' + date.getHours() : '' + date.getHours(),
        mm:
          date.getMinutes() < 10
            ? '0' + date.getMinutes()
            : '' + date.getMinutes(),
        ss:
          date.getSeconds() < 10
            ? '0' + date.getSeconds()
            : '' + date.getSeconds(),
      };
      return format.replace(/MM|dd|yyyy|hh|mm|ss/gi, (matched) => map[matched]);
    },
    onRowSelected(items) {

      this.link = items[0];
      this.isAddNewUserSidebarActive = true;
    },
    handleRemove(id) {
      this.idRemove = id;


      this.modalEliminarLinkShow = !this.modalEliminarLinkShow;
    },
    handleDetail(link) {
      console.log('linkk:::', link)

      this.link = link;
      this.isAddNewUserSidebarActive = true; /* !this.isAddNewUserSidebarActive; */
    },
    async getLinkDePago() {
      //const token = localStorage.getItem("token");

      /* var config = {
        method: "get",
        url: `${backendUrl}/link-pago/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };


        const resp = await axios(config);
        this.linksSinFiltrar = resp.data.link; */
      this.$store.commit('appConfig/changeLoading', true);
      try {
        this.linksSinFiltrar = this.$store.state.appConfig.linksDePago;
        if (this.linksSinFiltrar.length >= 0) {
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
        }
      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);
      }
    },
    linksDepago(id) {
      const linkProjectActive = this.linksSinFiltrar.filter(
        (e) => e.id_project === id /* && e.date_remove == null */
      );
  
      for (let i = 0; i < linkProjectActive.length; i++) {
        linkProjectActive[i].date_from = moment(
          linkProjectActive[i].date_from
        ).format('YYYY/MM/DD');
        if (linkProjectActive[i].date_to !== null) {
          linkProjectActive[i].date_to = moment(
            linkProjectActive[i].date_to
          ).format('L');
        }
        // if (this.verificaEstado(linkProjectActive[i])) {
        //   linkProjectActive[i].date_remove = moment(new Date()).format('L');
        // }
      }

      if (linkProjectActive.length > 0) {
        this.$store.commit('appConfig/changeLoading', false);
      }

      let result = linkProjectActive.map((elem) => ({
        ...elem,
        estado: this.verificaEstado(elem),
      }));

      this.rowsLength = linkProjectActive.length;
      return result;
    },
    hideFiltrarModal() {
      this.$refs['modal-center'].hide();
    },

    copiar(id, pass) {
      this.linkCopiado = '¡Enlace copiado al portapapeles!';
      try {
        window.navigator.clipboard.writeText(
          `${frontEndUrl}pages/miscellaneous/linkPagoVistaPrevia/${id}?pass=${pass}`
        );
      } catch (e) {
        alert('Falta SSL');
      }
    },
    hideEliminarModal() {
      this.$refs['modal-eliminar-link'].hide();
    },
    async eliminarLinkDePago(id) {
      const token = localStorage.getItem('token');

      var data = JSON.stringify({
        id: id,
      });

      var config = {
        method: 'put',
        url: `${backendUrl}/link-pago/rm`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      try {
        this.$store.commit('appConfig/changeLoading', true);
        const resp = await axios(config);
        if (resp.data.code == 2) {
          this.showEliminarLinkSuccess('success', resp.data.message);

          const resp2 = await axios({
            method: 'get',
            url: `${backendUrl}/link-pago/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.refresh = true;

          this.linksSinFiltrar = resp2.data.link;
          this.$store.commit('appConfig/saveLinksDePago', this.linksSinFiltrar);
        }

     
        this.$refs['modal-eliminar-link'].hide();
      } catch (error) {
      }
    },
    rowClicked(record, index) {
      this.link = record;
      this.isAddNewUserSidebarActive = true;
    },
    validateDates() {
      if (new Date(this.valueDesde) > new Date(this.valueHasta)) {
        this.errorDate = true;
      } else {
        this.errorDate = false;
        this.hideFiltrarModal();
      }
    },
    showSuscripcionNotFoundToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Suscripción no encontrada',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showEliminarLinkSuccess(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          text: 'El link ha sido dado de baja',
          icon: 'XIcon',
          variant,
        },
      });
    },
    searchSuscripcion() {
      if (this.buscar != 'suscripción') {
        this.showSuscripcionNotFoundToast('danger');
      }
    },
    showGenerarReporteErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al Crear el Reporte',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.pill-style {
  margin-right: 4px;
  margin-bottom: 2px;
}

.data-items-size {
  font-size: 12px;
}

::v-deep .table th {
  text-align: center !important;
}

.action-button {
  border: none;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}

.label-size {
  font-size: 14px;
}

.line {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 24px;
}

.per-page-selector {
  width: 90px;
}
.clipboard-text{
  font-weight: 600;
  text-align: center;
  font-size: 18px;

}
.alert-body{
  text-align: center;
}
.v-select.vs--single .vs__selected {
  background: transparent !important;
  border-radius: 20px !important;
  color: white !important;
}

.table thead th {
  white-space: nowrap !important;
}

.margen {
  margin-left: 50%;
}

.modal-button-size {
  width: 166px;
}

#exportarButton:hover {
  box-shadow: none;
  text-shadow: none;
}

.btn-info:hover {
  box-shadow: none !important;
}

#label-confirmacion {
  margin-left: 8%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: clipboard-text;
  font-size: 14px;
}

#id-popup {
  font-size:12px;
}

/* #detalle-link-pago .btn{
  padding: 0.786rem 4px;
} */

.paginador {
  margin-top: 20px;
}
.table-div {
  min-height: 340px;
}
</style>
